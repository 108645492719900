import React from 'react';
import Layout from '../components/Layout';
import {
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    Link,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import {graphql, useStaticQuery} from "gatsby"


const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        marginTop: '20px',
        marginBottom: '20px',
    },
    table: {
        '& tableCell': {
            verticalAlign: 'top',
        },
    },
    tableCell: {
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        marginTop: '30px',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

const CvTable = ({cvTable}) => {
    const classes = useStyles();
    const tableRows = cvTable.map((row) => {
        return (
            <TableRow>
                <TableCell className={classes.tableCell}>
                    {row.zeitraum}
                </TableCell>
                <TableCell>
                    {row.beschreibung}
                </TableCell>
            </TableRow>
        )
    });

    return (
        <Table className={classes.table}>
            <TableBody>
                {tableRows}
            </TableBody>
        </Table>
    );
};

const TeamMemberCard = ({teamMember}) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Card className={classes.card}>
                <Grid
                    spacing={3}
                    container
                >
                    <Grid item xs={12} sm={4}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {teamMember.name}
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{__html: teamMember.lawFields.childMarkdownRemark.html}}
                                gutterBottom variant="subtitle1" component="h3" color="textSecondary">
                            </Typography>
                            <Typography gutterBottom variant="body2" component="h3" color="textSecondary"
                                        className={classes.wrapIcon}>
                                <EmailIcon className={classes.icon} fontSize="small"/>
                                <Link href={`mailto:${teamMember.eMailAddress}`}>
                                    {teamMember.eMailAddress}
                                </Link>
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <CvTable cvTable={teamMember.cvTable}/>
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="h3">
                                Beratungsschwerpunkte
                            </Typography>
                            <p>
                                {teamMember.consultingTopics.join(' • ')}
                            </p>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
            <Divider/>
        </React.Fragment>
    );
};

export default function App() {
    const data = useStaticQuery(graphql`
    {
      allContentfulTeammitglied(sort: {fields: weight, order: ASC}) {
        totalCount
        teamMembers: nodes {
          weight
          name
          consultingTopics
          eMailAddress
          cvTable {
            zeitraum
            beschreibung
          }
          lawFields {
            lawFields
            childMarkdownRemark {
              html
            }
          }
        }
      }
      heroImage:file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lobby-1.jpg"}) {
        img:childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    const teamCards = data.allContentfulTeammitglied.teamMembers.map((teamMember) => {
        return (
            <TeamMemberCard teamMember={teamMember}/>
        );
    });

    return (
        <Layout heroHeadline1="Team"
                heroImage={data.heroImage.img.fluid}
        >
            <Container>
                {teamCards}
            </Container>
        </Layout>
    );
}
